import React from 'react';
import { makeStyles, alpha, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';

import Alert from '@material-ui/lab/Alert';

import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AppBar from '@material-ui/core/AppBar';

import Link from '@material-ui/core/Link';


import Button from '@material-ui/core/Button';
import theme from "../theme/theme"


import PropTypes from "prop-types";


import { useState } from 'react';

import TransparencyImage from "../images/transparency.png";
import CollaborativeWorkImage from "../images/collaborative_work.png";
import InnovationImage from "../images/innovation.png";
import TraningImage from "../images/training.png";

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import HomeIcon from '@material-ui/icons/Home';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import SvgIcon from '@material-ui/core/SvgIcon';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const AddressIcon = (() => {
    return (
      <SvgIcon viewBox="44 28 80 80"  color="primary" fontSize="large">
	<g>
		<circle class="st0" cx="87.99" cy="59.9" r="2.21"/>
		<path class="st0" d="M87.99,86.43c0,0,15.47-11.05,15.47-24.32c0-8.55-6.93-15.47-15.47-15.47s-15.47,6.93-15.47,15.47
			C72.52,75.38,87.99,86.43,87.99,86.43z M87.99,53.27c3.66,0,6.63,2.97,6.63,6.63s-2.97,6.63-6.63,6.63s-6.63-2.97-6.63-6.63
			S84.33,53.27,87.99,53.27z"/>
		<path class="st0" d="M90.56,90.03c-0.77,0.55-1.67,0.82-2.57,0.82s-1.8-0.28-2.57-0.82c-0.37-0.27-5.19-3.76-9.64-9.25
			l-6.76,18.92h37.94l-6.76-18.92C95.74,86.27,90.93,89.76,90.56,90.03z"/>
		<path class="st0" d="M123.09,96.39l-8.73-21.83c-0.34-0.84-1.15-1.39-2.05-1.39h-7.17c-0.55,1.12-1.15,2.21-1.8,3.24l8.32,23.28
			h9.5c0.01,0,0.01,0,0.02,0c1.22,0,2.21-0.99,2.21-2.21C123.38,97.09,123.28,96.71,123.09,96.39z"/>
		<path class="st0" d="M70.84,73.17h-7.17c-0.9,0-1.72,0.55-2.05,1.39l-8.84,22.11c-0.27,0.68-0.19,1.45,0.22,2.06
			c0.41,0.61,1.1,0.97,1.83,0.97h9.5l8.32-23.28C71.99,75.37,71.39,74.29,70.84,73.17z"/>
	</g>
</SvgIcon>
    )
});


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //height: 650,
    },
    title: {
        color: theme.palette.secondary.dark,
        fontSize: 24,
        fontWeight: 700,
        paddingTop: theme.spacing(4)
      },
      buttonUnderline: {
        backgroundColor: theme.palette.secondary.dark,
        height: 14,
        width: 50,
        margin: 'auto',
        marginTop: theme.spacing(3),

        marginBottom: theme.spacing(4),
      },
      buttonUnderlineValues: {
        backgroundColor: theme.palette.secondary.dark,
        height: 8,
        width: 30,
        margin: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
      },
    intro: {
        
      },
      introBox: {
        width: '90%',
          margin: 'auto',
          color: theme.palette.secondary.dark,
        //backgroundColor: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 500,
      },
      grid: {
          //height: 500
          marginBottom: theme.spacing(4)
      },
      social: {
        paddingTop: theme.spacing(4),
        textAlign: 'left',
      },
      imageBoxVertical: {
        maxWidth: 120,
        borderRadius: 0,
        margin: 'auto',
        backgroundColor: 'transparent'
    },
    mediaVertical: {
        height: 0,
        paddingTop: '100%', // 16:9
        margin: 'auto'
    },
      rootGrid: {
          maxWidth: '70%',
          margin: 'auto',
          marginTop: theme.spacing(6)
      },
      gridItem: {
          marginBottom: theme.spacing(8),
      },
      contactForm: {
          textAlign: 'left',
          width: '100%',
          padding: theme.spacing(4)
      },
      input: {
          width: '100%',
          fontSize: 20,
          marginTop: theme.spacing(0.5),
          borderRadius: 2
      },
      inputText: {
        width: '100%',
        height: 100,
        fontSize: 20,
        marginTop: theme.spacing(2)
    },
    field: {
        backgroundColor: theme.palette.secondary.dark,
        padding: theme.spacing(1),
        borderRadius: 2,
        marginTop: 10
    },
    margin: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
    buttonBox: {
        marginTop: theme.spacing(6),
        textAlign: 'center',
    },
    buttonStyle: {
        color: theme.palette.secondary.main,
        fontWeight: 600,
    }, 
    tableCell: {
      borderBottom:"none",
      '&:nth-child(1)': {
        textAlign: 'right'
      }
    },
    link: {
      color: theme.palette.secondary.dark
    }
}))

const StyledButton = withStyles({
    root: {
      background: '#fff',
      height: 32,
      padding: '0 30px',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.main
      },
    },
    label: {
      textTransform: 'capitalize',
    },
  })(Button);

const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

function RedditTextField(props) {
    const classes = useStylesReddit();
  
    return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
  }

  function RedditTextArea(props) {
    const classes = useStylesReddit();
  
    return <TextareaAutosize InputProps={{ classes, disableUnderline: true }} {...props} />;
  }

  const useStylesReddit = makeStyles((theme) => ({
    root: {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: theme.palette.secondary.light,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
      '&$focused': {
        backgroundColor: theme.palette.secondary.light,
        boxShadow: `${alpha(theme.palette.secondary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.secondary.main,
      },
    },
    focused: {},
  }));

function ContactForm() {


  const [name, setName] = useState(''); 
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [headerOpen, toggleHeader] = useState(false);

  const classes = useStyles();

    return (
        <div className={classes.contactForm}>
    <form className={classes.form} name="contact" action="#openModal" method="post" data-netlify="true" onSubmit={(e) => {
        e.preventDefault();

        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "contact",
            "name": name,
            "email": email,
            "message": message
          })
        })
          .then(() => {
            setName('');
            setEmail('');
            setMessage('');

            toggleHeader(!headerOpen);

            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          })
          .catch(error => console.log(error));

      }}>
        <div className="fields">
            <RedditTextField type="text" name="name" id="name" label={'Your name'} className={classes.margin} variant="filled"
                value={name}
                onChange={e => setName(e.target.value)} 
            />
            <RedditTextField type="email" name="email" id="email" label={'Your email'} className={classes.margin} variant="filled"
                value={email}
                onChange={e => setEmail(e.target.value)} 
            />
            <RedditTextField type="text" name="message" id="message" label={'Your message'} className={classes.margin} variant="filled"
                value={message}
                onChange={e => setMessage(e.target.value)} 
                multiline
                rows={10}
            />
        </div>

        <div className={classes.buttonBox}>
            <StyledButton type="submit" variant="outlined">
                <Typography className={classes.buttonStyle}>SUBMIT</Typography>
            </StyledButton>
        </div>
        {/*<ul className="actions">
          <li>
            <h3 className="red">
            <input type="submit" className="red" value="Send Message" />
            </h3>
          </li>
    </ul>*/}
      </form>
      </div>
    )
}

// ContactForm.propTypes = {
//     classes: PropTypes.object.isRequired
//   };

const useAlertStyles = makeStyles((theme) => ({
    alert: {
        display: 'flex',
        zIndex: 1000,
        position: 'absolute',
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

export default function ContactSection(props) {

    const classes = useStyles();
    const classesAlert = useAlertStyles();


    return (
        <div className={classes.root}>
            {/*<AppBar><Alert severity="success">This is a success alert — check it out!</Alert></AppBar>*/}
            <div className={classes.introBox}>
        <Typography className={classes.title}>CONTACT</Typography>
        <Grow timeout={1000} in={props.render}><div className={classes.buttonUnderline} /></Grow>
        <Typography className="red">
          Feel free to contact us if you would like to embrace our mission or if you have a tough challenge.
        </Typography>
        <Grid container className={classes.grid}>
        <Grid item xs={12} sm={12} md={6}>
        <ContactForm />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className={classes.social}>
            <TableContainer>
          <Table>
            <TableBody>

            <TableRow>
              <TableCell className={classes.tableCell}>
              {//<IconUmbrella color="primary" fontSize="large"/>
}
<AddressIcon />
              </TableCell>

              <TableCell className={classes.tableCell}>
                <Link  className={classes.link}  target="_blank" href="https://www.google.com/maps/place/Alameda+dos+Oceanos+142,+1990-506+Lisboa/@38.7841641,-9.0987351,19z/data=!3m1!4b1!4m5!3m4!1s0xd1931f4a996f049:0x61f5cec17a4351ae!8m2!3d38.7841641!4d-9.0981866">
                  <Typography>Lisbon, Portugal</Typography>
                </Link>
              </TableCell>
            </TableRow>

            <TableRow>
            <TableCell className={classes.tableCell}>
              <AlternateEmailIcon color="primary" fontSize="large"/>
            </TableCell>
              <TableCell className={classes.tableCell}>
              <Link href="mailto:info@litthub.com" className={classes.link}><Typography>info@litthub.com</Typography></Link>
              </TableCell>
            
            </TableRow>
              <TableRow>
            <TableCell className={classes.tableCell}>
              <LinkedInIcon color="primary" fontSize="large"/>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Link href="https://www.linkedin.com/company/litthub/" target="_blank" className={classes.link}><Typography>LinkedIn Page</Typography></Link>
              </TableCell>
            </TableRow>
            <TableRow>
            <TableCell className={classes.tableCell}>
            <SvgIcon color="primary" aria-hidden="true" focusable="false" style={{ fontSize: 33, paddingLeft: 2 }}><path d="M22 24l-5.25-5l.63 2H4.5A2.5 2.5 0 0 1 2 18.5v-15A2.5 2.5 0 0 1 4.5 1h15A2.5 2.5 0 0 1 22 3.5V24M12 6.8c-2.68 0-4.56 1.15-4.56 1.15c1.03-.92 2.83-1.45 2.83-1.45l-.17-.17c-1.69.03-3.22 1.2-3.22 1.2c-1.72 3.59-1.61 6.69-1.61 6.69c1.4 1.81 3.48 1.68 3.48 1.68l.71-.9c-1.25-.27-2.04-1.38-2.04-1.38S9.3 14.9 12 14.9s4.58-1.28 4.58-1.28s-.79 1.11-2.04 1.38l.71.9s2.08.13 3.48-1.68c0 0 .11-3.1-1.61-6.69c0 0-1.53-1.17-3.22-1.2l-.17.17s1.8.53 2.83 1.45c0 0-1.88-1.15-4.56-1.15m-2.07 3.79c.65 0 1.18.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27m4.17 0c.65 0 1.17.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27z" /></SvgIcon>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Link href="https://discord.gg/qkrxN6AS6y" target="_blank" className={classes.link}><Typography>Discord Server</Typography></Link>
              </TableCell>
            </TableRow>
            </TableBody>
            </Table>
            </TableContainer>
          </div>
        </Grid>
        
        </Grid>
        </div>
        </div>
    )
}